body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  font-size: 62.5%;
}

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
}

* {
  font-family: 'Love Bold';
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
}

button {
  border: none;
  outline: none;
}

@font-face {
  font-family: 'Love Bold';
  src: url('../public/fonts/Love_OTF_Bold.woff') format('woff');
}

@font-face {
  font-family: 'Love Medium';
  src: url('../public/fonts/Love_OTF_Medium.woff') format('woff');
}

@font-face {
  font-family: 'Love Light';
  src: url('../public/fonts/Love_OTF_Light.woff') format('woff');
}

/* @font-face {
  font-family: 'LoveLove Bold';
  src: url('../public/fonts/LoveLove_OTF_Bold.otf') format('truetype'),
    url('../public/fonts/LoveLove_OTF_Bold.otf') format('opentype'),
    url('../public/fonts/LoveLove_OTF_Bold.otf') format('woff'),
    url('../public/fonts/LoveLove_OTF_Bold.otf') format('woff2');
} */
